#video-background {
/*  making the video fullscreen  */
  position: fixed;
  right: 0; 
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto;
  z-index: -100;
}
.body_bg {
	background-color: #1F618D;
}
.login_container {
  color:#FFFFFF;
  text-align: center;
  background-color: #000000;
  opacity: 0.6;
  top: 80px;
  padding: 60px 20px;
}

.logo_container img{
	width: 200px;
}
.login__row {
  height: 5rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.login__input {
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  background: transparent;
  color: #FFFFFF;
  border: none;
  height: 3rem;
  margin-top: 1rem;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
}
.login__input:focus {
  border: none !important;
}
input, button {
    outline: none;
}
.login_form_group {
	margin-bottom: 0px;
	padding-top: 35px;
}
.login_btn_container {
	padding-top: 50px;
}
.login_btn {
	background-color: #154360;
	width: 50%;
	transition: transform .2s;
    outline: none;
    color: #FFFFFF;
    outline: none !important;
}
.login_btn:hover {
	color: #FFFFFF !important;
	-ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}
.landing_container {
	padding-top: 20%;
}
.landing_content {
	background-color: #154360;
    text-align: center;
    color: #FFFFFF;
    font-size: 25px;
    border-right: 10px solid #1F618D;
    margin-bottom: 25px;
    padding: 100px 0px; 
    cursor: pointer;
}
.list_bg {
	background-color: #154360;
}
.list_header {
	text-align: center;
    padding: 25px;
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 600;
}
.list_bg_container {
  border-radius: 10px;
  box-shadow: 0px 0px 12px 2px #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
}
.list_container {
	list-style-type: none;
	color: #FFFFFF;
	font-size: 15px;
	padding: 20px 10px;
}
.list_container li {
	   width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid #989A98;
    font-size: 18px;
    cursor: pointer;
}
@media screen and (min-width: 1000px) {
	.list_container li {
		text-align: center;
	}
}
.select_player {
    background-color: #1F618D;
    border: none !important;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    outline: none !important;
    width: 100%;
    font-size: 15px;
}
.select_server {
    background-color: #1F618D;
    border: none !important;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    outline: none !important;
    width: 100%;
    font-size: 15px;
}
.select_player option {
  outline: none !important;
}
.text_align_center {
  text-align: center;
}